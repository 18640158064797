import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import VerifiedIcon from "@mui/icons-material/Verified";
import List from "@mui/material/List";
import { Link, Redirect } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { BASE_URL } from "../components/config/config";
import { useHistory } from 'react-router-dom';

import "../Styles/style.css";
import {
  RAZORPAY_KEY,
  PAYMENT_NAME,
  PAYMENT_DESCRIPTION,
} from "../components/config/config";
import { Alert, InputAdornment, Snackbar, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import CenterRegistrationApi from "../components/centerRegistration/Api/api";
import CenterComponentApi from "../components/centerComponent/api/api";
import { IndianStates } from "../assets/IndianStates";
import CopyButton from "../components/ServicesStatus/ClipbaordButton";
import axios from "axios";
import moment from "moment";
// Modal css
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxwidth: 700,
  bgcolor: "background.paper",
  border: "2px solid gray",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  color: "black",
};

//   List render
const listStyle = {
  width: "100%",
  // maxWidth: 360,
  bgcolor: "background.paper",
};
const ResponsiveFormControl = styled(FormControl)({
  width: "100%",
  marginBottom: "10px",
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#0958b5" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
};

const AVAILABLE_TIME_PREFERENCES = [
  "IMMEDIATELY",
  "WITHIN_24_HOURS",
  "SPECIFIC_DATE_AND_TIME",
];

class PublicTicketBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceRequired: "",
      specificRequirement: "",
      name: "",
      phoneNumber: "",
      alternatePhoneNumber: "",
      houseNumber: "",
      locality: "",
      city: "",
      state: "",
      country: "INDIA",
      pincode: "",
      timePreference: "",
      offerCode: "OniT 2024",
      specific_date_time: new Date(),

      toast: {
        open: false,
        msg: "",
        duration: 5000,
        type: "",
      },
      isFormValid: false,
      servicesList: [],

      isRegistering: false,
      center_obj_id:"",
      isCenterExist: false,
      centerDetails: {},
      centerName: "",
      TicketModalOpen: false,
      ticketModalData: {},
      tickectId: "",
      verifyOtp: false,
      otp: "",
      paymentStatus: "",
      needToPay: false,
    };
  }

  componentDidMount() {
    this.getAllServicesExists();

    let qrId = window.location.hash.split("/")[2];
    console.log(qrId, "qr id");

    this.getCenterByQrid(qrId);
  }

  getCenterByQrid(qrId) {
    let payload = {
      qr_id: qrId,
    };

    new CenterRegistrationApi()
      .getCenterByQrId(payload)
      
      .then((res) => {
      
        console.log("+++============",res.data.doesCenterExists)
        // if the qr of e-rickshaw is scanned it goes in this if condition and to PublicPickDropQr.js
        if (res.data.doesCenterExists?.services?.primary_services?.[0] === "64462802f77b1ff1d68890fd" && res.data.doesCenterExists?.vehicleId === "65e2e3078151df1858864cab") {
          this.props.history.push(`/bookingPickDrop/${res.data.doesCenterExists?.qr_details?.qr_id}`);
        }
        // else if(moment().diff(res.data.doesCenterExists?.extended_date, 'days')>15 && res.data.doesCenterExists?.payment_details?.paid_for_onboarding_kit === false)
        //   this.props.history.push('/booking');
        
        this.setState({
          isCenterExist: res.data.isCenterExist,
          centerDetails: res.data.doesCenterExists,
          centerName: res.data.doesCenterExists?.center_name,
          center_obj_id:res.data.doesCenterExists?._id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getAllServicesExists() {
    new CenterRegistrationApi()
      .getAllActiveServices()
      .then((res) => {
        this.setState({
          servicesList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  showDialog = (open, type, msg) => {
    this.setState({
      toast: {
        open: open,
        msg: msg,
        duration: 5000,
        type: type,
      },
    });
  };

  closeDialog = (open = false) => {
    this.setState({
      toast: {
        open: open,
        msg: "",
        duration: 5000,
        type: "",
      },
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    if (name == "pincode") {
      if (value.length <= 6) {
        this.setState({
          pincode: value,
        });
      }

      return;
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        console.log(this.state);
      }
    );
  };
  onChangeSpecificDateAndTime(newValue) {
    this.setState({ specific_date_time: newValue });
  }

  handleCreateTickect = async (ress) => {
    const {
      serviceRequired,
      specificRequirement,
      name,
      phoneNumber,
      alternatePhoneNumber,
      houseNumber,
      locality,
      city,
      state,
      country,
      pincode,
      timePreference,
      offerCode,
      center_obj_id,
    } = this.state;
    if (
      !(
        serviceRequired &&
        name &&
        phoneNumber &&
        houseNumber &&
        pincode &&
        timePreference &&
        offerCode
      )
    ) {
      this.setState({
        isFormValid: true,
      });

      this.showDialog(true, "error", "Please fill in all required details");
      return;
    }

    this.setState({
      isRegistering: true,
    });

    if (this.state.timePreference == "SPECIFIC_DATE_AND_TIME") {
      if (!this.state.specific_date_time) {
        this.showDialog(true, "error", "Please fill in Specific date and time");
        return;
      }
    }

    let payload = {
      service_provided_for: serviceRequired,
      specific_requirement: specificRequirement,
      personal_details: {
        primary_phone: {
          country_code: "+91",
          mobile_number: phoneNumber,
        },
        alternate_phone: {
          country_code: "+91",
          mobile_number: alternatePhoneNumber,
        },
        name: name,
      },
      time_preference: {
        time_preference_type: timePreference,
        specific_date_time: this.state.specific_date_time,
      },
      offers_applied: {
        offer_code: offerCode,
      },
      address_details: {
        house_number: houseNumber,
        locality: locality,
        city: city,
        state: state,
        pincode: pincode,
        country: country,
      },
       center_obj_id: center_obj_id,
      urldata: window.location.href,
      // urldata: "https://app.onit.services/#/booking/INA2021",
    };

    await new CenterComponentApi()
      .PublicTicketBooking(payload)
      .then(async (res) => {
        if (res?.message === "Please complete the payment process") {
          this.handleInitiatePayment(
            ress?.consumerDetails?._id,
            ress?.token,
            res?.data?._id
          );
          this.setState({tickectId: res?.data?._id})
        } else {
          this.setState({ ticketModalData: res?.data });
          alert("Your request created successfully");
          this.setState({ TicketModalOpen: true });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isRegistering: false,
        });
        this.showDialog(true, "error", err?.response?.data?.message);
      });
  };
  handleSendOtp = async () => {
    const { phoneNumber } = this.state;

    // Check if the mobile number is empty
    if (!phoneNumber) {
        // Display error message or handle the error as per your requirement
        console.error("Mobile number cannot be empty");
        return; // Exit the function if validation fails
    }

    // Check if the mobile number has exactly 10 digits
    if (phoneNumber.length !== 10) {
        // Display error message or handle the error as per your requirement
        console.error("Please enter a valid 10-digit mobile number");
        return; // Exit the function if validation fails
    }

    try {
        const _data = {
            country_code: "+91",
            mobile_number: phoneNumber,
        };

        // Disable the submit button until the API call is completed
        this.setState({
            isRegistering: true,
        });

        await axios({
            method: "post",
            url: `${BASE_URL}consumerAppAppRoute/sent-otp-by-web`,
            data: _data,
        }).then((res) => this.setState({ verifyOtp: true }));
    } catch (error) {
        console.log(error);
    } finally {
        // Enable the submit button after the API call is completed
        this.setState({
            isRegistering: false,
        });
    }
};





  handleVerifyOtp = async () => {
    try {
      const _data = {
        country_code: "+91",
        mobile_number: this.state.phoneNumber,
        otp: parseInt(this.state.otp),
      };
      await axios({
        method: "post",
        url: `${BASE_URL}consumerAppAppRoute/verify-otp-by-web`,

        data: _data,
      }).then((res) => {
        this.setState({ verifyOtp: false });
        this.handleCheckUserExist();
      });
    } catch (error) {
      // setOtpSend(true);
      alert("Wrong OTP");
    }
  };

  handleCheckUserExist = async () => {
    try {
      let res1 = await axios.post(
        `${BASE_URL}consumerAppAppRoute/register-consumer-by-web`,
        {
          personal_details: {
            phone: {
              country_code: "+91",
              mobile_number: this.state.phoneNumber,
            },
            name: this.state.name,
          },
          address_details_permanent: {
            pincode: this.state.pincode,
          },
        }
      );

      if (res1?.data?.status === 200) {
        this.handleCreateTickect(res1?.data?.data);
      } else {
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  handleInitiatePayment = async (id, token, tickect) => {
    try {
      const _data = {
        amount: 99,
        phone: this.state.phoneNumber,
        type: "Web",
        userId: id,
        userType: "Consumer",
        ticket_obj_id: tickect,
      };

      const res2 = await axios({
        method: "post",
        url: `${BASE_URL}payment/phonepe`,
        headers: {
          "x-access-token": token,
        },
        data: _data,
      });
      const { code, data } = res2?.data;

      if (code !== "PAYMENT_INITIATED") {
        return;
      }

      const url = data?.instrumentResponse?.redirectInfo?.url;

      const merchantTransactionId = data?.merchantTransactionId;
      window.open(url, "_blank", "noreferrer");
      this.checkStatusCron(merchantTransactionId, token);
    } catch (error) {
      console.log(error);
    }
  };
  checkStatusCron = async (merchantTransactionId, token) => {
    this.setState({ paymentStatus: "Payment Pending" });
    try {
      const payload = {
        merchantTransactionId,
      };
      let time = 20000;
      for (var i = 0; i < time; i++) {
        if (i < time) {
          const res = await axios({
            method: "post",
            url: `${BASE_URL}payment/check-status`,
            data: payload,
            headers: {
              "x-access-token": token,
            },
          });

          if (res?.data?.code === "PAYMENT_SUCCESS") {
            this.setState({ paymentStatus: "Payment done" });

            setTimeout(() => {
              this.handleUpdateTicketStatus("success");
            }, 2000);
            break;
          }
          if (res?.data?.code === "PAYMENT_DECLINED") {
            // alert("Payment declined");

            this.setState({ paymentStatus: "Payment declined" });
            this.handleUpdateTicketStatus("failed");
            break;
          }
          if (res?.data?.code === "PAYMENT_ERROR") {
            this.setState({ paymentStatus: "Payment declined" });
            this.handleUpdateTicketStatus("failed");
            break;
          }

          if (res?.data?.code === "TIMED_OUT") {
            // alert("Time out");

            this.setState({ paymentStatus: "Payment out" });
            this.handleUpdateTicketStatus("failed");
            break;
          }
        }
      }
    } catch (e) {
      this.setState({ paymentStatus: "Something went wrong! Try again" });
      this.handleUpdateTicketStatus("failed");
      console.log("e", e);
    }
  };

  handleUpdateTicketStatus = async (status) => {
    const payload = {
      ticket_obj_id: this.state.tickectId,
      payment_status: status, //[success, failed]
      payment_amount: 99,
    };
    try {
      const res = await axios({
        method: "post",
        url: `${BASE_URL}center/update-ticket-after-payByWeb`,
        data: payload,
      });

      if (res?.data?.success === 1) {
        this.setState({ ticketModalData: res?.data?.data });
        alert("Your request created successfully");
        this.setState({ TicketModalOpen: true });
      }
    } catch (err) {
      alert("Payment failed! Your request not created");
    }
  };

  handleClose = () => {
    this.setState({
      TicketModalOpen: false,
    });
  };

  render() {
    const {
      toast: snackBarValues,
      isFormValid,
      serviceRequired,
      specificRequirement,
      name,
      phoneNumber,
      alternatePhoneNumber,
      houseNumber,
      locality,
      city,
      state,
      country,
      pincode,
      timePreference,
      offerCode,
      otp,
    } = this.state;
    return (
      <div>
        {/* Ticket creation modal code */}
        <Modal
          keepMounted
          open={this.state.TicketModalOpen}
          // open={true}
          onClose={this.handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%", // Adjust width for smaller screens
              maxWidth: "90vw", // Set maximum width relative to viewport width
              bgcolor: "background.paper",
              border: "2px solid gray",
              boxShadow: 24,
              borderRadius: 4,
              color: "black",
              textAlign: "center", // Center align content
              p: { xs: 2, sm: 3, md: 4 }, // Adjust padding for different screen sizes
              "& .MuiTypography-root": {
                mb: 2, // Adjust margin bottom for typography elements
              },
              "& .MuiList-root": {
                width: "100%", // Ensure list takes full width
              },
              "& .MuiListItem-root": {
                display: "block", // Display list items as blocks
              },
              "& .MuiListItem-root + .MuiDivider-root": {
                margin: "8px 0", // Adjust margin between list items and dividers
              },
            }}
          >
            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              <VerifiedIcon color="success" fontSize="large" />
              Hurray! Your Request has been created
            </Typography>
            <Typography sx={{ mt: 2 }}>
              {/* List content */}
              <List
                sx={{ width: "100%" }}
                component="nav"
                aria-label="mailbox folders"
              >
                <ListItem button>
                  <ListItemText primary="Ticket Id :" />
                  <Typography>{this.state.ticketModalData?.ticket_id}</Typography>
                </ListItem>
                <Divider />
                <ListItem button divider>
                  <ListItemText primary="Ticket Status :" />
                  <Typography>
                    {this.state.ticketModalData?.ticket_status}
                  </Typography>
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Created At :" />
                  <Typography>
                    {this.state.ticketModalData?.createdAt}
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText primary="Tracking Link :" />
                  <Typography>
                    <CopyButton
                      webLink={`https://app.onit.services/#/ServiceStatus/${this.state.ticketModalData?._id}`}
                    />
                    <Link
                      style={{ marginLeft: "17px" }}
                      to={`/ServiceStatus/${this.state.ticketModalData?._id}`}
                    >
                      Click me
                    </Link>
                  </Typography>
                </ListItem>
              </List>
            </Typography>
          </Box>
        </Modal>

        {snackBarValues.open && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBarValues.open}
            autoHideDuration={snackBarValues.duration}
            onClose={this.closeDialog}
          >
            <Alert
              onClose={this.closeDialog}
              severity={snackBarValues.type}
              sx={{ width: "100%" }}
            >
              {snackBarValues.msg}
            </Alert>
          </Snackbar>
        )}
        <h2 className="text-align-center" style={{ color: 'black' }}>Namaste !</h2>
        <h4 className="text-align-center" style={{ color: 'black' }}>
          Please help us understand your need
        </h4>
        <Item>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">
                  Service You Required
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Service You Required"
                  name="serviceRequired"
                  onChange={this.handleChange}
                  value={serviceRequired}
                  error={isFormValid && !serviceRequired}
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {this.state.servicesList?.map((ite) => {
                    return (
                      <MenuItem key={ite._id} value={ite._id}>
                        <div style={{ width: "100%" }}>{ite.service_name}</div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Specific Requirement - it helps"
                style={stylesCss.inputBox}
                name="specificRequirement"
                value={specificRequirement}
                onChange={this.handleChange}
                error={isFormValid && !specificRequirement}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Name (Contact Person)"
                style={stylesCss.inputBox}
                name="name"
                value={name}
                onChange={this.handleChange}
                error={isFormValid && !name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Phone Number"
                style={stylesCss.inputBox}
                name="phoneNumber"
                value={phoneNumber}
                onChange={this.handleChange}
                error={isFormValid && !phoneNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>+91</Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Alternate Phone Number"
                style={stylesCss.inputBox}
                name="alternatePhoneNumber"
                value={alternatePhoneNumber}
                onChange={this.handleChange}
                // error={isFormValid && !alternatePhoneNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>+91</Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="House Number / Street"
                style={stylesCss.inputBox}
                name="houseNumber"
                value={houseNumber}
                onChange={this.handleChange}
                error={isFormValid && !houseNumber}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Locality"
                style={stylesCss.inputBox}
                name="locality"
                value={locality}
                onChange={this.handleChange}
                error={isFormValid && !locality}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="City"
                style={stylesCss.inputBox}
                name="city"
                value={city}
                onChange={this.handleChange}
                error={isFormValid && !city}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  State
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="State"
                  name="state"
                  onChange={this.handleChange}
                  value={state}
                  error={isFormValid && !state}
                >
                  {IndianStates?.map((ite) => {
                    return <MenuItem value={ite.value}>{ite.value}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Country"
                style={stylesCss.inputBox}
                name="country"
                value={country}
                onChange={this.handleChange}
                error={isFormValid && !country}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Pincode"
                style={stylesCss.inputBox}
                name="pincode"
                value={pincode}
                onChange={this.handleChange}
                error={isFormValid && !pincode}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Time Preference
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Time Preference"
                  name="timePreference"
                  onChange={this.handleChange}
                  value={timePreference}
                  error={isFormValid && !timePreference}
                >
                  {AVAILABLE_TIME_PREFERENCES?.map((ite) => {
                    return <MenuItem value={ite}>{ite}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>

            {this.state.timePreference == "SPECIFIC_DATE_AND_TIME" ? (
              <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(params) => <TextField {...params} />}
                    label="Select date and time"
                    value={this.state.specific_date_time}
                    onChange={(newValue) => {
                      this.onChangeSpecificDateAndTime(newValue);
                    }}
                    minDate={new Date()}
                    minTime={new Date(0, 0, 0, 8)}
                    maxTime={new Date(0, 0, 0, 20)}
                  />
                </LocalizationProvider>
              </Grid>
            ) : (
              ""
            )}

            <Grid item xs={12} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Offer Code"
                style={stylesCss.inputBox}
                name="offerCode"
                value={offerCode}
                onChange={this.handleChange}
                error={isFormValid && !offerCode}
              />
            </Grid>
            {this.state.isCenterExist && (
              <>
                <Grid item xs={12} md={6}>
  <TextField
    id="demo-helper-text-misaligned"
    label="Partner Name"
    style={{...stylesCss.inputBox , color: 'black'}}
    name="offerCode"
    value={this.state.centerName}
    disabled={true}
    InputProps={{
      style: { fontWeight: 'bold', color:'black' }
    }}
  />
</Grid>

              </>
            )}
            {this.state.verifyOtp === true ? (
              <>
               <Grid item xs={12} md={6}>
  <TextField
    id="demo-helper-text-misaligned"
    label="OTP"
    style={{ ...stylesCss.inputBox, backgroundColor: 'lightblue' }}
    name="otp"
    value={otp}
    onChange={this.handleChange}
  />
</Grid>

                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    onClick={() => this.handleVerifyOtp()}
                  >
                    VERIFY OTP
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    onClick={() => this.handleSendOtp()}
                    disabled={this.state.isRegistering}
                  >
                    SUBMIT
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h2>
                    {this.state.paymentStatus !== ""
                      ? this.state.paymentStatus
                      : ""}
                  </h2>
                </Grid>
              </>
            )}
          </Grid>
        </Item>
      </div>
    );
  }
}

export default PublicTicketBooking;
